<template>
    <div class="container rtl">
        <div class="row pb-4">
            <!-- <label class="btn btn-primary active m-2">
                نوێکردنەوە
                <input type="checkbox" v-model="resetable">
            </label>
            <button class="btn btn-info m-2" onclick="window.history.back()">
                <span>گەڕانەوە</span>
            </button> -->
            <div class="col">
                <table class="wasl-table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">ژ. پسوڵە</th>
                            <td scope="col" class="text-center">{{return_wasl.company_return_number}}</td>
                            <th scope="col" class="text-center">کۆمپانیا</th>
                            <td scope="col" class="text-center">{{return_wasl.company_name}}</td>
                            <th scope="col" class="text-center">بەروار</th>
                            <td scope="col" class="text-center">{{return_wasl.company_return_date}}</td>
                            <th scope="col" class="text-center">جۆری پسوڵە</th>
                            <td scope="col" class="text-center">{{return_wasl.company_return_type == 'naqd' ? 'نەقد' : 'قەرز'}}</td>
                            <th scope="col" class="text-center">جۆری دراو</th>
                            <td scope="col" class="text-center">{{return_wasl.company_return_currency == 'iqd' ? 'دینار' : 'دۆلار'}}</td>
                            <th scope="col" class="text-center">نرخی دۆلار</th>
                            <td scope="col" class="text-center">{{$service.toIQD(return_wasl.company_return_dollar_price)}}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <form @submit.prevent="addcompany_return_items" autocomplete="off">
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>کۆدی بەرهەم</label>
                    <div class="form-group">
                        <input v-model="return_item.item_barcode" @input="checkBarcode" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>ناوی بەرهەم</label>
                    <div class="form-group">
                        <select class="form-control" @change="checkItemID" v-model="return_item.item_id" required>
                            <option v-for="(item, index) in items" :key="index" :value="item.item_id">
                                {{item.item_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>گەڕانەوە بە</label>
                    <div class="form-group">
                        <select class="form-control" v-model="return_item.buy_type" required>
                            <option value="dana">{{return_item.item_unit_tak}}</option>
                            <option value="karton">{{return_item.item_unit}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>بڕی گەڕاوە</label>
                    <input v-model="return_item.company_return_items_qty" class="form-control" type="number"
                        step="any" required>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی دۆلار</label>
                    <div class="input-group mb-3">
                        <input :disabled="return_wasl.company_return_currency == 'iqd'"
                            v-model="return_item.company_return_items_usd" class="form-control" type="number"
                            step="any" required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی دینار</label>
                    <div class="input-group mb-3">
                        <input :disabled="return_wasl.company_return_currency == 'usd'"
                            v-model="return_item.company_return_items_iqd" class="form-control" type="number"
                            step="any" required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">د.ع</span>
                        </div>

                    </div>
                </div>
                
                <div class="col-2">
                    <label style="color:white">-</label>
                    <div class="form-group">
                        <button type="submit" class="btn btn-success m-2">زیادکردن</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="row mt-2">
            <div class="col">
                <table class="my-table" border="1">
                    <thead>
                        
                        <tr>
                            <th scope="col" class="text-center" rowspan="2">  بارکۆد </th>
                            <th scope="col" class="text-center" rowspan="2"> ناوی بەرهەم </th>
                            <th scope="col" class="text-center" rowspan="2"> جۆر </th>
                            <th scope="col" class="text-center" colspan="2">بڕ </th>
                            <th scope="col" class="text-center" colspan="2"> نرخی دینار </th>
                            <th scope="col" class="text-center" colspan="2"> نرخی دۆلار </th>
                            <th scope="col" class="text-center" rowspan="2"> کۆی نرخی دینار </th>
                            <th scope="col" class="text-center" rowspan="2"> کۆی نرخی دۆلار </th>
                            <th scope="col" class="text-center" rowspan="2">سڕینەوە</th>
                        </tr>
                        <tr>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(item, index) in return_wasl.items" :key="index">
                            <td>{{item.item_barcode}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.category_name}}</td>
                            <td>{{item.company_return_items_qty}} {{item.item_unit_tak}}</td>
                            <td>{{(item.company_return_items_qty/item.item_quantity).toFixed(2)}} {{item.item_unit}}</td>
                            <td>{{$service.toIQD(item.one_iqd)}}</td>
                            <td>{{$service.toIQD(item.many_iqd)}}</td>
                            <td>{{$service.toUSD(item.one_usd)}}</td>
                            <td>{{$service.toUSD(item.many_usd)}}</td>
                            <td>{{$service.toIQD(item.total_iqd)}}</td>
                            <td>{{$service.toUSD(item.total_usd)}}</td>
                            <td>
                                <button @click="opencompany_return_items(item , 'remove')" type="button"
                                    class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="text-center" colspan="3">کۆی گشتی</th>
                            <td class="text-center">{{return_wasl.qty}}</td>
                            <td class="text-center">{{return_wasl.qty_ko ? return_wasl.qty_ko.toFixed(2) : 0}}</td>
                            <td class="text-center" colspan="4">-</td>
                            <td class="text-center">{{$service.toIQD(return_wasl.iqd)}}</td>
                            <td class="text-center">{{$service.toUSD(return_wasl.usd)}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی بەرهەمی سەر پسوڵەی کۆمپانیا</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger"
                            @click="deletecompany_return_items">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                return_id: 0,
                return_wasl: {},
                selected_item: {
                    company_return_items_usd: 0,
                    company_return_items_iqd: 0,
                },
                return_item: {
                    company_return_items_usd: 0,
                    company_return_items_iqd: 0,
                    buy_type: 'karton'
                },
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            items() {
                return this.$store.getters.items
            },
        },
        methods: {
            checkBarcode() {
                let check = this.items.filter(f => {
                    return f.item_barcode == this.return_item.item_barcode
                })
                if (check[0]) {
                    this.return_item.item_id = check[0].item_id
                    this.return_item.item_unit = check[0].item_unit
                    this.return_item.item_unit_tak = check[0].item_unit_tak
                    this.return_item.item_quantity = check[0].item_quantity
                } else {
                    this.return_item.item_id = undefined
                }
            },
            checkItemID() {
                let check = this.items.filter(f => {
                    return f.item_id == this.return_item.item_id
                })
                if (check[0]) {
                    this.return_item.item_barcode = check[0].item_barcode
                    this.return_item.item_unit = check[0].item_unit
                    this.return_item.item_unit_tak = check[0].item_unit_tak
                    this.return_item.item_quantity = check[0].item_quantity
                } else {
                    this.return_item.item_barcode = undefined
                }
            },
            getcompany_return_items() {
                axios.post('company_return_items/read.php', {
                        return_id: this.return_id
                    })
                    .then(r => {
                        console.log(r);
                        this.return_wasl = r.data.wasl
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addcompany_return_items() {
                let params = this.return_item
                params.company_return_id = this.return_id
                let count = this.return_item.company_return_items_qty
                let iqd = this.return_item.company_return_items_iqd
                let usd = this.return_item.company_return_items_usd
                if (this.return_item.buy_type == 'karton') {

                    count = this.return_item.company_return_items_qty * this.return_item.item_quantity
                    params.company_return_items_qty = count

                    iqd = (this.return_item.company_return_items_iqd / this.return_item.item_quantity)
                    usd = (this.return_item.company_return_items_usd / this.return_item.item_quantity)

                    console.log(iqd);
                    params.company_return_items_iqd = iqd
                    params.company_return_items_usd = usd
                }
                axios.post('company_return_items/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.return_item = {
                                company_return_items_usd: 0,
                                company_return_items_iqd: 0,
                                buy_type: 'karton'
                            }
                            this.getcompany_return_items()
                            this.msg = {
                                model: true,
                                text: 'کاڵا  زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletecompany_return_items() {
                let params = {
                    company_return_items_id: this.selected_company_return_items.company_return_items_id
                }
                axios.post('company_return_items/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getcompany_return_items()
                            this.msg = {
                                model: true,
                                text: '  کاڵا سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opencompany_return_items(item, type) {
                this.selected_company_return_items = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                }
            }
        },
        mounted() {
            this.return_id = this.$route.params.id;
            $('#deleteModal').modal('hide')
            this.getcompany_return_items()
        },
    }
</script>
<style>
    .my-table{
        width: 100%;
        border: 1px solid black;
    }
    .my-table th{
        padding: 10px;
        background-color: rgb(255, 230, 0);
        color: black;
    }
    .my-table td{
        padding: 5px;
    }
    .wasl-table{
        width: 100%;
        border: 1px solid black;
    }

    .wasl-table th {
        padding: 10px;
        background-color: rgb(255, 230, 0);
    }
</style>